.amp-input-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    width: 100%;
}

.amp-input-label {
    color: var(--primary, #000000);
    font-feature-settings: 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 700;
    line-height: 20px;
    margin: 0;
}

.amp-text-field {
    width: 100%;

    :global {
        .MuiOutlinedInput-root.Mui-disabled {
            input {
                color: var(--text-disabled, #b5b5b5);

                &::placeholder {
                    color: var(--text-disabled, #b5b5b5);
                }
            }

            fieldset {
                border: 1px solid var(--color-border-default, #DEE3EE) !important;
            }
        }

        .MuiOutlinedInput-root {
            display: flex;
            padding: 12px 16px;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;
            border-radius: 4px;
            background: var(--color-surface, #FFF);
            font-family: "Plus Jakarta Sans", sans-serif;
            border: none;

            input {
                color: var(--text-primary, #000);
                font-feature-settings: 'liga' off;
                font-family: "Plus Jakarta Sans";
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                padding: 0;

                &::placeholder {
                    color: var(--text-secondary, #8A93A8);
                    font-family: "Plus Jakarta Sans";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                }
            }

            fieldset {
                border: 1px solid var(--color-border-default, #DEE3EE);
                top: 0;

                legend {
                    display: none;
                }
            }

            &:hover fieldset {
                border: 1px solid var(--color-border-default, #DEE3EE);
            }

            &.Mui-focused fieldset {
                border: 1px solid var(--color-border-default, #DEE3EE);
            }
        }

        .MuiSelect-outlined.MuiSelect-outlined {
            padding: 0;
        }

        .MuiFormHelperText-root {
            margin-left: 0;
            color: var(--error, #ef4014);
            font-feature-settings: 'liga' off;
            font-family: "Plus Jakarta Sans";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 15px;
            margin-top: 8px;
        }
    }
}