@import './variables';
@import '/src/styles/theme-variables';

.amp-text {
  margin: 0;
  padding: 0;
  font-family: Plus Jakarta Sans, sans-serif;
  // Font sizes
  @each $size, $values in $font-sizes {
    &.size-#{$size} {
      font-size: map-get($values, 'size');
      line-height: map-get($values, 'line-height');
    }
  }

  // Font weights
  @each $weight, $value in $font-weights {
    &.weight-#{$weight} {
      font-weight: $value;
    }
  }

  // Colors
  @each $color, $value in $theme-colors {
    &.color-#{$color} {
      color: $value;
    }
  }

  // Text alignment
  &.align-left { text-align: left; }
  &.align-center { text-align: center; }
  &.align-right { text-align: right; }
  &.align-justify { text-align: justify; }

  // Text transform
  &.transform-uppercase { text-transform: uppercase; }
  &.transform-lowercase { text-transform: lowercase; }
  &.transform-capitalize { text-transform: capitalize; }
  &.transform-none { text-transform: none; }
}