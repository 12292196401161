@import './variables';
@import '../../spacers/css/variables';

.amp-container {
    display: flex;
    box-sizing: border-box;

    // Direction variants
    &.direction-row { flex-direction: row; }
    &.direction-column { flex-direction: column; }

    // Align variants
    &.align-flex-start { align-items: flex-start; }
    &.align-center { align-items: center; }
    &.align-flex-end { align-items: flex-end; }
    &.align-stretch { align-items: stretch; }
    &.align-baseline { align-items: baseline; }

    // Justify variants
    &.justify-flex-start { justify-content: flex-start; }
    &.justify-center { justify-content: center; }
    &.justify-flex-end { justify-content: flex-end; }
    &.justify-space-between { justify-content: space-between; }
    &.justify-space-around { justify-content: space-around; }
    &.justify-space-evenly { justify-content: space-evenly; }

    // Wrap variants
    &.wrap-nowrap { flex-wrap: nowrap; }
    &.wrap-wrap { flex-wrap: wrap; }
    &.wrap-wrap-reverse { flex-wrap: wrap-reverse; }

    // Grid mode
    &.is-grid {
        display: grid;
        &.direction-row {
            grid-template-columns: repeat(12, 1fr);
            grid-template-rows: auto;
        }
        &.direction-column {
            grid-template-columns: 1fr;
            grid-template-rows: auto;
        }
    }

    // Grid spans
    @each $breakpoint, $min-width in $breakpoints {
        @media (min-width: $min-width) {
            @for $i from 1 through 12 {
                &.grid-#{$breakpoint}-#{$i} {
                    grid-column: span #{$i};
                }
            }
        }
    }

    // Height variants
    &.height-auto { height: auto; }
    &.height-full { height: 100%; }
    &.height-screen { height: 100vh; }

    // Width variants
    &.width-auto { width: auto; }
    &.width-full { width: 100%; }
    &.width-screen { width: 100vw; }

    // Flex variants
    @each $flex-size, $value in $flex-sizes {
        &.flex-#{$flex-size} { 
            flex: $value; 
        }
    }

    // Flex grow variants
    @each $flex-size, $value in $flex-sizes {
        &.flex-grow-#{$flex-size} { 
            flex-grow: $value; 
        }
    }

    // Flex shrink variants
    @each $flex-size, $value in $flex-sizes {
        &.flex-shrink-#{$flex-size} { 
            flex-shrink: $value; 
        }
    }

    // Container color variants
    @each $color-name, $color-value in $container-colors {
        &.container-color-#{$color-name} {
            background-color: $color-value;
        }
    }

    // Container overflow variants
    @each $overflow-name, $overflow-value in $container-overflow {
        &.container-overflow-#{$overflow-name} {
            overflow: $overflow-value;
        }
    }

    // Container position variants
    @each $container-position-name, $container-position-value in $container-position {
        &.container-position-#{$container-position-name} {
            position: $container-position-value;
        }
    }

    // Container position location variants
    @each $spacer-size in $spacer-sizes {
        @each $container-position-location-name, $container-position-location-value in $container-position-location {
            &.container-position-#{$container-position-location-name}-#{$spacer-size} {
                @each $location, $value in $container-position-location-value {
                    #{$location}: calc(#{$spacer-size} * #{$spacer-size-constant});
                }
            }
        }
    }
}