$theme-colors: (
    'primary': var(--primary),
    'primary-light': var(--primary-light),
    'primary-dark': var(--primary-dark),
    // Secondary Colors
    'secondary': var(--secondary),
    'secondary-light': var(--secondary-light),
    'secondary-dark': var(--secondary-dark),
    // Status Colors
    'error': var(--error),
    'error-light': var(--error-light),
    'warning': var(--warning),
    'success': var(--success),
    // Text Colors
    'text-primary': var(--text-primary),
    'text-secondary': var(--text-secondary),
    // Background Colors
    'background-default': var(--background-default),
    'background-paper': var(--background-paper),
    'background-dark': var(--background-dark),
    'white': #ffffff,
    'surface': #F8F9FC,
    'gray': #7b7c7e,
);