.amp-divider {
    border: none;
    background-color: var(--color-border-default, #DEE3EE);
    flex-shrink: 0;

    &.horizontal {
        width: 100%;
        height: 1px;
    }

    &.vertical {
        width: 1px;
        height: 100%;
        min-height: 20px; // Provide a minimum height when used vertically
    }
}