.amp-spinner {
    &.size-sm {
        width: 16px !important;
        height: 16px !important;
    }

    &.size-md {
        width: 24px !important;
        height: 24px !important;
    }

    &.size-lg {
        width: 32px !important;
        height: 32px !important;
    }

    &.color-primary {
        color: var(--primary);
    }

    &.color-secondary {
        color: var(--secondary);
    }

    &.color-white {
        color: var(--color-surface);
    }
}