@import './variables';

.amp-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    
    // Size variants
    @each $size, $value in $icon-sizes {
        &.size-#{$size} {
            width: $value;
            height: $value;
            
            svg {
                width: $value;
                height: $value;
            }
        }
    }

    // Color variants
    @each $color-name, $color-value in $icon-colors {
        &.color-#{$color-name} {
            color:  var(--#{$color-value});
            
            svg {
                fill: currentColor;
            }
        }
    }
}