$icon-sizes: (
    'sm': 20px,
    'md': 24px,
    'lg': 32px,
);

$icon-colors: (
    'primary',
    'secondary',
    'error',
    'warning',
    'success',
    'white',
);