@import "./variables";

.amp-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    min-width: unset;
    box-shadow: none;
    border-radius: 64px !important;
    padding: 12px 24px !important;
    
    // Width variants
    &.width-normal {
        width: auto;
    }
    
    &.width-wide {
        width: 100%;
    }
    
    @each $color-type in $button-color-types {
        &.color-#{$color-type}-filled {
            background-color: var(--#{$color-type});
            color: #ffffff;
            transition: background-color 0.2s ease;
    
            // Add !important to ensure these styles persist during form submission
            &:hover:not(:disabled) {
                background-color: var(--#{$color-type}-dark) !important;
            }
            
            &:active:not(:disabled) {
                background-color: var(--#{$color-type}-dark) !important;
                transform: scale(0.98); // Optional: adds a slight press effect
            }
            
            // Force the background color to stay visible during submission
            &:focus:not(:disabled) {
                background-color: var(--#{$color-type}-dark) !important;
            }
            
            @media (hover: none) {
                &:hover:not(:disabled) {
                    background-color: var(--#{$color-type}) !important;
                }
            }
            
            &:disabled {
                color: #ffffff !important;
                background-color: var(--#{$color-type}-light) !important;
                opacity: .5;
            }
        }

        &.color-#{$color-type}-outlined {
            background-color: var(--color-surface);
            border: 1px solid var(--color-border-default);
            color: var(--#{$color-type});
            transition: all 0.2s ease;

            &:hover:not(:disabled) {
                background-color: var(--#{$color-type});
                color: var(--color-surface);
            }
            
            &:active:not(:disabled) {
                background-color: var(--#{$color-type});
                color: var(--color-surface);
                transition: none;
            }
            
            @media (hover: none) {
                &:hover:not(:disabled) {
                    background-color: var(--color-surface);
                    color: var(--#{$color-type});
                }
            }
            
            &:disabled {
                color: var(--#{$color-type}-light);
                opacity: .5;
            }
        }

        &.color-#{$color-type}-text {
            color: var(--#{$color-type});
            transition: background-color 0.2s ease;

            &:hover:not(:disabled) {
                background-color: var(--light-gray);
            }
            
            &:disabled {
                color: var(--#{$color-type}-light);
                opacity: .5;
            }
        }
    }

    @each $size, $properties in $button-sizes {
        &.size-#{$size} {
            @each $property, $value in $properties {
                #{$property}: #{$value} !important;
            }
        }
    }
}

.amp-button-label {
    font-feature-settings: 'liga' off;
    font-family: "Plus Jakarta Sans";
    font-size: var(--font-size-3, 14px);
    font-style: normal;
    font-weight: 700;
    line-height: var(--font-size-7, 24px);
    letter-spacing: 1.4px;
    text-transform: uppercase;
    user-select: none; // Prevents text selection on tap
}