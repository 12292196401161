.modal {
    display: flex;
    align-items: center;
    justify-content: center;
}

.content {
    max-width: min(90vw, 600px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
	position: relative;
    background: #FFF;
    border-radius: 16px;
    border: 1px solid var(--color-border-default, #DEE3EE);
}