$flex-sizes: (
    '0': 0,
    '1': 1,
    '2': 2,
    '3': 3,
    '4': 4,
    '5': 5,
    '6': 6,
    '7': 7,
    '8': 8,
    '9': 9,
    '10': 10,
    '11': 11,
    '12': 12,
);

$container-colors: (
    'white': var(--color-surface, #FFFFFF),
    'surface': var(--color-surface-dark, #F8F9FC),
);

$container-overflow: (
    'hidden': hidden,
    'visible': visible,
    'scroll': scroll,
    'auto': auto,
);

$container-position: (
    'relative': relative,
    'absolute': absolute,
    'fixed': fixed,
    'sticky': sticky,
);

$container-position-location: (
    'top' : (
        top : 0,
    ),
    'right' : (
        right : 0,
    ),
    'bottom' : (
        bottom : 0,
    ),
    'left' : (
        left : 0,
    ),
    'top-right':  (
        top : 0,
        right : 0,
    ),
    'top-left' : (
        top : 0,
        left : 0,
    ),
    'bottom-right' : (
        bottom : 0,
        right : 0,
    ),
    'bottom-left' : (
        bottom : 0,
        left : 0,
    ),
);

// Grid breakpoints
$breakpoints: (
    'xs': 0,
    'sm': 600px,
    'md': 960px,
    'lg': 1280px,
    'xl': 1920px
);