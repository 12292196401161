// Font Sizes and Line Heights
$font-sizes: (
  'h6': (size: 14px, line-height: 20px),
  'h5': (size: 24px, line-height: 32px),
  'h4': (size: 32px, line-height: 40px),
  'h3': (size: 40px, line-height: 48px),
  'h2': (size: 56px, line-height: 64px),
  'h1': (size: 72px, line-height: 80px),
  'caption_xl': (size: 28px, line-height: 40px),
  'caption_lg': (size: 20px, line-height: 28px),
  'caption_md': (size: 14px, line-height: 20px),
  'caption_sm': (size: 12px, line-height: 16px),
  'body_xxxxl': (size: 56px, line-height: 72px),
  'body_xxxl': (size: 40px, line-height: 56px),
  'body_xxl': (size: 32px, line-height: 48px),
  'body_xl': (size: 24px, line-height: 32px),
  'body_lg': (size: 18px, line-height: 28px),
  'body_md': (size: 16px, line-height: 24px),
  'body_sm': (size: 14px, line-height: 24px),
  'body_xsm': (size: 12px, line-height: 20px),
  'body_xxsm': (size: 10px, line-height: 16px),
  'button_xxl': (size: 40px, line-height: 32px, letter-spacing: 4px),
  'button_xl': (size: 24px, line-height: 24px, letter-spacing: 2.4px),
  'button_lg': (size: 20px, line-height: 24px, letter-spacing: 2px),
  'button_md': (size: 16px, line-height: 28px, letter-spacing: 1.6px),
  'button_sm': (size: 14px, line-height: 24px, letter-spacing: 1.4px),
);

// Font Weights
$font-weights: (
  'normal': 500,
  'bold': 700,
  'light': 400
);