.amp-card {
    display: flex;
    border-radius: 16px;
    background: #FFF;
    border: 1px solid var(--color-border-default, #DEE3EE);
    box-sizing: border-box;
    box-shadow: none !important;
    margin: 0 !important;

    // Flex direction variants
    &.direction-row {
        flex-direction: row;
    }
    &.direction-column {
        flex-direction: column;
    }

    // Align items variants
    &.align-flex-start {
        align-items: flex-start;
    }
    &.align-center {
        align-items: center;
    }
    &.align-flex-end {
        align-items: flex-end;
    }

    // Justify content variants
    &.justify-flex-start {
        justify-content: flex-start;
    }
    &.justify-center {
        justify-content: center;
    }
    &.justify-flex-end {
        justify-content: flex-end;
    }
}